<template>
  <el-form
    :model="addForm"
    :rules="rules"
    ref="addForm"
    label-width="82px"
    label-position="left"
    class="demo-form-inline dialog-form dialog-form-inline"
  >
    <el-form-item label="企业名称：" prop="name">
      <el-input v-model.trim="addForm.name" placeholder="请输入企业名称"></el-input>
    </el-form-item>
    <el-form-item label="企业缩写：" prop="abbreviation">
      <el-input v-model.trim="addForm.abbreviation" placeholder="请输入企业缩写"></el-input>
    </el-form-item>
    <el-form-item label="企业地址：" prop="address">
      <el-input v-model.trim="addForm.address" placeholder="请输入企业地址"></el-input>
    </el-form-item>
    <el-form-item label="营业执照：" prop="businessLicense">
      <el-input v-model.trim="addForm.businessLicense"></el-input>
    </el-form-item>
    <el-form-item label="组织代码：" prop="code">
      <el-input v-model.trim="addForm.code" placeholder="请输入组织代码"></el-input>
    </el-form-item>
    <el-form-item label="法人：" prop="corporation">
      <el-input v-model.trim="addForm.corporation" placeholder="请输入法人"></el-input>
    </el-form-item>
    <el-form-item label="描述：" prop="description">
      <el-input v-model.trim="addForm.description" placeholder="请输入描述"></el-input>
    </el-form-item>
    <el-form-item label="商标：" prop="logo">
      <el-input v-model.trim="addForm.logo"></el-input>
    </el-form-item>
    <el-form-item label="电话：" prop="phone">
      <el-input v-model.trim="addForm.phone"></el-input>
    </el-form-item>
    <el-form-item label="资质证明：" prop="qualification">
      <el-input v-model.trim="addForm.qualification"></el-input>
    </el-form-item>
    <el-form-item label="安全证书：" prop="safetyCertificati">
      <el-input v-model.trim="addForm.safetyCertificati"></el-input>
    </el-form-item>
    <!-- <el-form-item label="状态" prop="status">
      <el-select v-model.trim="addForm.status" placeholder="请选择">
        <el-option key="0" label="正常" value="0"></el-option>
        <el-option key="1" label="锁定" value="1"></el-option>
      </el-select>
    </el-form-item>-->
  </el-form>
</template>
<script>
import { insertDept, updateDept, getDeptById } from "@/api/lib/api.js";
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    deptId: {
      type: String,
      default: null
    },
    parentId: {
      type: String,
      default: "0"
    },
    loadData: {
      type: Function,
      default: null
    }
  },
  data () {
    return {
      addForm: {
        id: "",
        name: "",
        address: "",
        abbreviation: "",
        businessLicense: "",
        code: "",
        corporation: "",
        description: "",
        phone: "",
        logo: "",
        qualification: "",
        safetyCertificati: "",
        /* status: "" */
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入企业名称",
            trigger: "blur"
          }
        ],
        abbreviation: [
          {
            required: true,
            message: "请输入企业简称",
            trigger: "blur"
          }
        ],
        address: [
          {
            required: true,
            message: "请输入企业地址",
            trigger: "blur"
          }
        ]
      }
    };
  },
  methods: {
    // 新增/保存企业
    onSave () {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          let data = {
            name: this.addForm.name,
            address: this.addForm.address,
            abbreviation: this.addForm.abbreviation,
            businessLicense: this.addForm.businessLicense,
            code: this.addForm.code,
            corporation: this.addForm.corporation,
            description: this.addForm.description,
            phone: this.addForm.phone,
            logo: this.addForm.logo,
            qualification: this.addForm.qualification,
            safetyCertificati: this.addForm.safetyCertificati,
            /* status: this.addForm.status */
          };
          if (this.title.indexOf("新增") !== -1) {
            data = {
              ...data,
              parentId: this.parentId
            };
            insertDept(data).then(res => {
              if (res.code === 0) {
                this.$message.success("新增成功");
                this.loadData();
              } else {
                this.$message.error("新增失败");
              }
            });
          } else {
            data = {
              ...data,
              id: this.deptId,
              parentId: this.parentId
            };
            updateDept(data).then(res => {
              if (res.code === 0) {
                this.$message.success("修改成功");
                this.loadData();
              } else {
                this.$message.error("修改失败");
              }
            });
          }
          this.$emit("onDialogClose");
        }
      });
    }
  },
  created () {
    if (this.deptId !== null) {
      getDeptById(this.deptId).then(res => {
        if (res.code === 0) {
          this.addForm = { ...res.data };
          this.parentId = res.data.parentId;
        }
      });
    }
  },
  mounted () { }
};
</script>